<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.memo-column
  font-size: 11px
</style>
<template lang="pug">
div
  button.btn.btn-light.text-primary.float-right.shadow-sm(type='button') Scheme 엑셀 다운로드
  h3.title.py-3 DB 연동정보
  .row.border-top
    .col.border-right.shadow-sm.p-0(style='min-height: 500px;')
      .px-3.py-1.bg-light
        small: strong 데이터베이스 목록

      b-list-group.rounded-0.border-0
        b-list-group-item.active(variant='light' button) Admin RO
        b-list-group-item(button) Partner API
        b-list-group-item(button) 워드프레스
    .col.border-right.shadow-sm.p-0(style='min-height: 500px')
      .px-3.py-1.bg-light
        small: strong Admin RO

      b-list-group.rounded-0.border-0
        b-list-group-item(button) 연결정보
        b-list-group-item(button) 로그
      .px-3.py-1.bg-light
        small: strong 테이블 목록

        //- a.float-right.text-secondary(href='#')
          small: strong 엑셀
      b-list-group.rounded-0.border-0
        b-list-group-item.active(button) reservation
        b-list-group-item(button) reservation_log
        b-list-group-item(button) store
        b-list-group-item(button) store_request
        b-list-group-item(button) store_request_log
        b-list-group-item(button) product
        b-list-group-item(button) product_stock
        b-list-group-item(button) product_log

    .col-6.border-right.shadow-sm.p-0.bg-light(style='min-height: 500px')
      .px-3.py-1.bg-light
        small: strong reservation
      table.table.mb-0.bg-white(style='font-size: 12px')
        //- thead
          tr
            th.py-1.bg-light 정책
            th.py-1.bg-light 값
        tbody
          //- tr
            td 표시이름
            td.p-0
              textarea.form-control.p-0.memo-column.rounded-0(rows=3) 예약목록
          tr
            td 메모
            td.p-0
              textarea.form-control.p-0.memo-column.rounded-0(rows=3) [예약자목록] *예약변경시 바로 수정되고 내역은 reservation_log에 있음

      table.table.bg-white(style='font-size: 12px')
        thead
          tr
            th.py-1.bg-light 필드명
            th.py-1.bg-light 데이터타입
            th.py-1.bg-light 설명
        tbody
          tr
            td id
            td BIGINT
            td.p-0
              textarea.form-control.p-0.memo-column.rounded-0(rows=3)
          tr
            td guest_name
            td VARCHAR
            td.p-0
              textarea.form-control.p-0.memo-column.rounded-0(rows=3) 예약자명+메모 (인원수)
          tr
            td store_name
            td VARCHAR
            td.p-0
              textarea.form-control.p-0.memo-column.rounded-0(rows=3) 가게지점명
          tr
            td created_at
            td DATETIME
            td.p-0
              textarea.form-control.p-0.memo-column.rounded-0(rows=3)
          tr
            td product_id
            td INT
            td.p-0
              textarea.form-control.p-0.memo-column.rounded-0(rows=3) 예약상품 ID

</template>

<script>

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      loading: false,
      sms_config: {},
      phone: '',
      code_requested: false,
      pintype: '',
      code: '',
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/sms_config`)
        this.sms_config = r.data.sms_config
        this.loading = true
      } catch (error) {
        console.log({error})
      }
    },

    async request_sms() {
      try {
        this.pintype = 'sms'
        const r = await this.$http.post(`/v1/property/${this.property_id}/sms/verify_sender`, {
          phone: this.phone,
          pintype: this.pintype,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')
        this.code_requested = true

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async request_ars() {
      try {
        this.pintype = 'vms'
        const r = await this.$http.post(`/v1/property/${this.property_id}/sms/verify_sender`, {
          phone: this.phone,
          pintype: this.pintype,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')
        this.code_requested = true

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async request2() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/sms/verify_sender/next`, {
          phone: this.phone,
          code: this.code,
          pintype: this.pintype,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.code_requested = false
        this.phone = ''
        this.code = ''
        this.pintype = ''

        this.$modal.show('dialog', {
          title: '알림',
          text: '등록되었습니다.',
        })

        this.load()

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    open_noti() {
      alert(`알림 표시합니다: 문자답변, 이메일답변, 가져오기성공`)
    },

    remove(config) {
      alert(`미구현 ${JSON.stringify(config)}`)
    }

  },
}
</script>
